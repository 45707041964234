.p-about {
  position: relative;
  padding: 100px 25px 76px;

  @include mq(md) {
    padding: 120px 0 120px;
  }
}

.p-about__heading {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.8657;
  letter-spacing: 0.1em;
  white-space: nowrap;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);
  font-feature-settings: initial;

  @include mq(md) {
    margin-left: 15px;
    font-size: 48px;
    line-height: 1.6279;
    text-align: center;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-about__body {
  margin-top: 30px;
  line-height: 2.3077;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transition-delay: 0.5s;
  transform: translateY(30px);

  @include mq(md) {
    margin-top: 32px;
    font-size: 24px;
    line-height: 2.6667;
    text-align: center;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-about__obj-left {
  position: absolute;
  top: 0;
  left: 0;
}

.p-about__obj01,
.p-about__obj02,
.p-about__obj03 {
  position: absolute;
  bottom: 100%;
  z-index: -1;
  height: 0;
  background-color: var(--secondarycolor);
  transition: height cubic-bezier(0.23, 0.6, 0.43, 0.99) 0.8s;
  transform: rotate(45deg) skewY(-45deg);
  transform-origin: bottom left;

  @include mq(md) {
    bottom: auto;
    transition-duration: 1s;
  }
}

.p-about__obj01 {
  left: -14px;
  width: 7px;
  margin-bottom: -119px;
  opacity: 0.4;

  @include mq(md) {
    bottom: 565px;
    left: -32px;
    width: 20px;
    margin-bottom: 0;
  }
}

.p-about.is-active .p-about__obj01 {
  height: 80px;

  @include mq(md) {
    height: 500px;
  }
}

.p-about__obj02 {
  left: -4px;
  width: 7px;
  margin-bottom: -134px;
  opacity: 0.2;

  @include mq(md) {
    bottom: 720px;
    left: 193px;
    width: 20px;
    margin-bottom: 0;
  }
}

.p-about.is-active .p-about__obj02 {
  height: 194px;
  transition-delay: 0.2s;

  @include mq(md) {
    height: 400px;
    transition-delay: 0.3s;
  }
}

.p-about__obj03 {
  left: -84px;
  width: 56px;
  margin-bottom: -241px;
  opacity: 0.8;

  @include mq(md) {
    bottom: 124px;
    left: -327px;
    width: 150px;
    margin-bottom: 0;
  }
}

.p-about.is-active .p-about__obj03 {
  height: 280px;
  transition-delay: 0.4s;

  @include mq(md) {
    height: 1200px;
    transition-delay: 0.6s;
  }
}
