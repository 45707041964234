.p-info {
  margin-top: 60px;

  @include mq(md) {
    margin-top: 132px;
  }
}

.p-info__content {
  padding: 0 25px;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);

  @include mq(md) {
    display: flex;
    width: 1160px;
    padding: 0;
    margin: 65px auto 0;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-info__link {
  display: block;
  padding: 26px 0 22.5px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.2);

  @include mq(md) {
    position: relative;
    width: 33.333%;
    padding: 45px 0 0;
    margin-right: 45px;
    border-top: 1px solid rgba(34, 34, 34, 0.2);
    border-bottom: 0;
  }

  &::before {
    @include mq(md) {
      position: absolute;
      top: -1px;
      left: 0;
      width: 0;
      height: 1px;
      content: '';
      background-color: var(--secondarycolor);
      transition: width 0.3s;
    }
  }

  &:first-child {
    padding-top: 30px;

    @include mq(md) {
      padding-top: 48px;
    }
  }
}

a.p-info__link {
  &:hover {
    text-decoration: underline;

    &::before {
      @include mq(md) {
        width: 230px;
      }
    }
  }
}

.p-info__date {
  font-family: var(--font-en);
  font-size: 12px;
  color: #999;
  letter-spacing: 0.1em;

  @include mq(md) {
    padding-left: 3px;
    font-size: 14px;
  }
}

.p-info__body {
  margin-top: 8px;
  font-size: 13px;
  line-height: 2.3333;
  letter-spacing: 0.1em;

  @include mq(md) {
    margin-top: 12px;
    font-size: 15px;
    line-height: 2.6667;
  }
}
