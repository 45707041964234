.p-about {
  position: relative;
  padding: 100px 25px 76px;
}
@media (min-width: 48em) {
  .p-about {
    padding: 120px 0 120px;
  }
}

.p-about__heading {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.8657;
  letter-spacing: 0.1em;
  white-space: nowrap;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);
  font-feature-settings: initial;
}
@media (min-width: 48em) {
  .p-about__heading {
    margin-left: 15px;
    font-size: 48px;
    line-height: 1.6279;
    text-align: center;
  }
}
.p-about__heading.is-active {
  opacity: 1;
  transform: translateY(0);
}

.p-about__body {
  margin-top: 30px;
  line-height: 2.3077;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transition-delay: 0.5s;
  transform: translateY(30px);
}
@media (min-width: 48em) {
  .p-about__body {
    margin-top: 32px;
    font-size: 24px;
    line-height: 2.6667;
    text-align: center;
  }
}
.p-about__body.is-active {
  opacity: 1;
  transform: translateY(0);
}

.p-about__obj-left {
  position: absolute;
  top: 0;
  left: 0;
}

.p-about__obj01,
.p-about__obj02,
.p-about__obj03 {
  position: absolute;
  bottom: 100%;
  z-index: -1;
  height: 0;
  background-color: var(--secondarycolor);
  transition: height cubic-bezier(0.23, 0.6, 0.43, 0.99) 0.8s;
  transform: rotate(45deg) skewY(-45deg);
  transform-origin: bottom left;
}
@media (min-width: 48em) {
  .p-about__obj01,
.p-about__obj02,
.p-about__obj03 {
    bottom: auto;
    transition-duration: 1s;
  }
}

.p-about__obj01 {
  left: -14px;
  width: 7px;
  margin-bottom: -119px;
  opacity: 0.4;
}
@media (min-width: 48em) {
  .p-about__obj01 {
    bottom: 565px;
    left: -32px;
    width: 20px;
    margin-bottom: 0;
  }
}

.p-about.is-active .p-about__obj01 {
  height: 80px;
}
@media (min-width: 48em) {
  .p-about.is-active .p-about__obj01 {
    height: 500px;
  }
}

.p-about__obj02 {
  left: -4px;
  width: 7px;
  margin-bottom: -134px;
  opacity: 0.2;
}
@media (min-width: 48em) {
  .p-about__obj02 {
    bottom: 720px;
    left: 193px;
    width: 20px;
    margin-bottom: 0;
  }
}

.p-about.is-active .p-about__obj02 {
  height: 194px;
  transition-delay: 0.2s;
}
@media (min-width: 48em) {
  .p-about.is-active .p-about__obj02 {
    height: 400px;
    transition-delay: 0.3s;
  }
}

.p-about__obj03 {
  left: -84px;
  width: 56px;
  margin-bottom: -241px;
  opacity: 0.8;
}
@media (min-width: 48em) {
  .p-about__obj03 {
    bottom: 124px;
    left: -327px;
    width: 150px;
    margin-bottom: 0;
  }
}

.p-about.is-active .p-about__obj03 {
  height: 280px;
  transition-delay: 0.4s;
}
@media (min-width: 48em) {
  .p-about.is-active .p-about__obj03 {
    height: 1200px;
    transition-delay: 0.6s;
  }
}

.p-info {
  margin-top: 60px;
}
@media (min-width: 48em) {
  .p-info {
    margin-top: 132px;
  }
}

.p-info__content {
  padding: 0 25px;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);
}
@media (min-width: 48em) {
  .p-info__content {
    display: flex;
    width: 1160px;
    padding: 0;
    margin: 65px auto 0;
  }
}
.p-info__content.is-active {
  opacity: 1;
  transform: translateY(0);
}

.p-info__link {
  display: block;
  padding: 26px 0 22.5px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.2);
}
@media (min-width: 48em) {
  .p-info__link {
    position: relative;
    width: 33.333%;
    padding: 45px 0 0;
    margin-right: 45px;
    border-top: 1px solid rgba(34, 34, 34, 0.2);
    border-bottom: 0;
  }
}
@media (min-width: 48em) {
  .p-info__link::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 0;
    height: 1px;
    content: "";
    background-color: var(--secondarycolor);
    transition: width 0.3s;
  }
}
.p-info__link:first-child {
  padding-top: 30px;
}
@media (min-width: 48em) {
  .p-info__link:first-child {
    padding-top: 48px;
  }
}

a.p-info__link:hover {
  text-decoration: underline;
}
@media (min-width: 48em) {
  a.p-info__link:hover::before {
    width: 230px;
  }
}

.p-info__date {
  font-family: var(--font-en);
  font-size: 12px;
  color: #999;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .p-info__date {
    padding-left: 3px;
    font-size: 14px;
  }
}

.p-info__body {
  margin-top: 8px;
  font-size: 13px;
  line-height: 2.3333;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .p-info__body {
    margin-top: 12px;
    font-size: 15px;
    line-height: 2.6667;
  }
}

.p-mv {
  position: relative;
  height: calc(100vh - 90px);
  margin-top: 90px;
  border-top: 1px solid #b4b4b4;
}
@media (min-width: 48em) {
  .p-mv {
    height: calc(100vh - 96px);
    margin-top: 96px;
  }
}

.p-mv__heading {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100%;
  padding: 0 0 0 30px;
  margin-top: -32px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.3846;
  color: #fff;
  letter-spacing: 0.2em;
  filter: blur(10px);
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transition-delay: 1s;
  transform: translate(-50%, -45%);
}
@media (min-width: 48em) {
  .p-mv__heading {
    top: 50%;
    max-width: 1200px;
    padding: 0 45px;
    margin-top: 0;
    font-size: 72px;
  }
}
.p-mv__heading.is-active {
  filter: blur(0);
  opacity: 1;
  transform: translate(-50%, -50%);
}

.p-mv__video {
  width: 100%;
  height: calc(100vh - 90px);
  object-fit: cover;
}
@media (min-width: 48em) {
  .p-mv__video {
    height: calc(100vh - 96px);
  }
}

.p-mv__scroll {
  display: none;
}
@media (min-width: 48em) {
  .p-mv__scroll {
    position: absolute;
    bottom: 0;
    left: 35px;
    display: inline-block;
    font-family: var(--font-en);
    font-size: 14px;
    color: #fff;
    writing-mode: vertical-rl;
  }
}
@media (min-width: 48em) {
  .p-mv__scroll::before {
    position: absolute;
    bottom: 0;
    left: 8.5px;
    width: 1px;
    height: 100px;
    content: "";
    background-color: #f0f0f0;
    animation: scrolldown 1.8s infinite;
  }
}
@media (min-width: 48em) {
  .p-mv__scroll::after {
    display: inline-block;
    width: 1px;
    height: 100px;
    margin-top: 15px;
    content: "";
    background-color: rgba(240, 240, 240, 0.2);
  }
}

@keyframes scrolldown {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
.p-recruit {
  position: relative;
  padding: 90px 25px 70px;
}
@media (min-width: 48em) {
  .p-recruit {
    padding: 190px 3.3854166667% 240px;
  }
}

.p-recruit__container {
  padding: 45px 30px 50px;
  background-image: url("/assets/images/pages/index/recruit_img.jpg");
  background-position: 50% 0;
  background-size: cover;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);
}
@media (min-width: 48em) {
  .p-recruit__container {
    padding: 188px 0 158px;
    background-image: url("/assets/images/pages/index/recruit_img_md.jpg");
  }
}
.p-recruit__container.is-active {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 48em) {
  .p-recruit__inner {
    position: relative;
    z-index: 1;
    max-width: 1480px;
    padding: 0 45px;
    margin: 0 auto;
  }
}

.p-recruit__heading {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #fff;
}

.p-recruit__heading-ja {
  margin-right: 10px;
  font-size: 16px;
  letter-spacing: 0.2em;
}
@media (min-width: 48em) {
  .p-recruit__heading-ja {
    margin-right: 19px;
    font-size: 36px;
  }
}

.p-recruit__heading-en {
  font-family: var(--font-en);
  font-size: 11px;
}
@media (min-width: 48em) {
  .p-recruit__heading-en {
    margin-top: 5px;
    font-size: 14px;
  }
}

.p-recruit__lede {
  margin-top: 10px;
  font-size: 23px;
  font-weight: 500;
  line-height: 1.8696;
  color: #fff;
  letter-spacing: 0.2em;
  white-space: nowrap;
  font-feature-settings: initial;
}
@media (min-width: 48em) {
  .p-recruit__lede {
    margin-top: 29px;
    margin-left: -5px;
    font-size: 72px;
    line-height: 1.5278;
    letter-spacing: 0.1em;
  }
}

.p-recruit__link {
  margin-top: 25px;
}
@media (min-width: 48em) {
  .p-recruit__link {
    margin-top: 52px;
  }
}

.p-recruit__obj01,
.p-recruit__obj02,
.p-recruit__obj03,
.p-recruit__obj04,
.p-recruit__obj05,
.p-recruit__obj06 {
  position: absolute;
  bottom: 100%;
  height: 0;
  background-color: var(--secondarycolor);
  transition: height cubic-bezier(0.23, 0.6, 0.43, 0.99) 0.8s;
  transform: rotate(45deg) skewY(-45deg);
  transform-origin: bottom left;
}
@media (min-width: 48em) {
  .p-recruit__obj01,
.p-recruit__obj02,
.p-recruit__obj03,
.p-recruit__obj04,
.p-recruit__obj05,
.p-recruit__obj06 {
    transition-duration: 1s;
  }
}

.p-recruit__obj04,
.p-recruit__obj05,
.p-recruit__obj06 {
  z-index: -1;
  transform-origin: top right;
}

.p-recruit__obj01 {
  left: -35px;
  width: 5px;
  margin-bottom: 7px;
  opacity: 0.8;
}
@media (min-width: 48em) {
  .p-recruit__obj01 {
    left: -99px;
    width: 20px;
    margin-bottom: -2px;
  }
}

.p-recruit.is-active .p-recruit__obj01 {
  height: 40px;
}
@media (min-width: 48em) {
  .p-recruit.is-active .p-recruit__obj01 {
    height: 150px;
  }
}

.p-recruit__obj02 {
  left: -33px;
  width: 5px;
  margin-bottom: -9px;
  opacity: 0.3;
}
@media (min-width: 48em) {
  .p-recruit__obj02 {
    left: -100px;
    width: 20px;
    margin-bottom: -84px;
  }
}

.p-recruit.is-active .p-recruit__obj02 {
  height: 80px;
  transition-delay: 0.2s;
}
@media (min-width: 48em) {
  .p-recruit.is-active .p-recruit__obj02 {
    height: 350px;
    transition-delay: 0.3s;
  }
}

.p-recruit__obj03 {
  left: -107px;
  width: 53px;
  margin-bottom: -101px;
  opacity: 0.6;
}
@media (min-width: 48em) {
  .p-recruit__obj03 {
    left: -362px;
    width: 210px;
    margin-bottom: -423px;
  }
}

.p-recruit.is-active .p-recruit__obj03 {
  height: 180px;
  transition-delay: 0.4s;
}
@media (min-width: 48em) {
  .p-recruit.is-active .p-recruit__obj03 {
    height: 655px;
    transition-delay: 0.6s;
  }
}

.p-recruit__obj04 {
  top: 298px;
  right: -74px;
  width: 53px;
  opacity: 0.3;
}
@media (min-width: 48em) {
  .p-recruit__obj04 {
    top: 442px;
    right: -297px;
    width: 210px;
  }
}

.p-recruit.is-active .p-recruit__obj04 {
  height: 232px;
  transition-delay: 0.8s;
}
@media (min-width: 48em) {
  .p-recruit.is-active .p-recruit__obj04 {
    height: 910px;
  }
}

.p-recruit__obj05 {
  top: 420px;
  right: 30px;
  width: 5px;
  opacity: 0.8;
}
@media (min-width: 48em) {
  .p-recruit__obj05 {
    top: 940px;
    right: 113px;
    width: 20px;
  }
}

.p-recruit.is-active .p-recruit__obj05 {
  height: 120px;
  transition-delay: 1.1s;
}
@media (min-width: 48em) {
  .p-recruit.is-active .p-recruit__obj05 {
    height: 450px;
    transition-delay: 1.3s;
  }
}

.p-recruit__obj06 {
  top: 400px;
  right: -7px;
  width: 5px;
  opacity: 0.6;
}
@media (min-width: 48em) {
  .p-recruit__obj06 {
    top: 838px;
    right: -70px;
    width: 20px;
  }
}

.p-recruit.is-active .p-recruit__obj06 {
  height: 102px;
  transition-delay: 1.4s;
}
@media (min-width: 48em) {
  .p-recruit.is-active .p-recruit__obj06 {
    height: 350px;
    transition-delay: 1.6s;
  }
}

.p-service {
  position: relative;
}
.p-service::before {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: calc(100% - 42px);
  content: "";
  background-color: #f0f0f0;
}
@media (min-width: 48em) {
  .p-service::before {
    right: 0;
    width: 93.75%;
    height: calc(100% - 75px);
    margin: 0 auto;
  }
}

.p-service__content {
  margin-top: 35px;
}
@media (min-width: 48em) {
  .p-service__content {
    margin-top: 67px;
  }
}

@media (min-width: 48em) {
  .p-service__inner {
    position: relative;
    display: flex;
    background-image: url("/assets/images/pages/index/service_img_md.jpg");
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
    transform: translateY(30px);
  }
}
@media (min-width: 48em) {
  .p-service__inner.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-service__img {
  display: none;
}
@media (min-width: 48em) {
  .p-service__img {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 840px;
    background-position: center;
    background-size: cover;
    opacity: 0;
    object-fit: cover;
  }
}

@media (min-width: 48em) {
  .p-service__img--01 {
    background-image: url("/assets/images/pages/index/service_img01_hover.jpg");
  }
}

@media (min-width: 48em) {
  .p-service__img--02 {
    background-image: url("/assets/images/pages/index/service_img02_hover.jpg");
  }
}

.p-service__new {
  padding: 40px 25px 70px;
}
@media (min-width: 48em) {
  .p-service__new {
    max-width: 1340px;
    padding: 80px 90px 197px;
    margin: 0 auto;
  }
}

.p-service__business {
  background-position: 50%;
  background-size: cover;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);
}
@media (min-width: 48em) {
  .p-service__business {
    position: relative;
    z-index: 1;
    width: 50%;
    height: 840px;
  }
}
.p-service__business.is-active {
  opacity: 1;
  transform: translateY(0);
}

.p-service__business--01 {
  background-image: url("/assets/images/pages/index/service_img01.jpg");
}
@media (min-width: 48em) {
  .p-service__business--01 {
    background-image: none;
  }
}

.p-service__business--02 {
  background-image: url("/assets/images/pages/index/service_img02.jpg");
}
@media (min-width: 48em) {
  .p-service__business--02 {
    background-image: none;
  }
}

.p-service__business--03 {
  padding: 43px 30px 52px;
  background-image: url("/assets/images/pages/index/service_img03.jpg");
}
@media (min-width: 48em) {
  .p-service__business--03 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 320px;
    padding: 0 50px 0 100px;
    background-image: url("/assets/images/pages/index/service_img03_md.jpg");
  }
}

.p-service__link {
  display: block;
  padding: 53px 25px 61px;
}
@media (min-width: 48em) {
  .p-service__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 110px 0;
    opacity: 0.5;
    transition: opacity 0.25s;
  }
}
@media (min-width: 48em) {
  .p-service__link.is-active {
    opacity: 1;
  }
}

.p-service__title {
  font-weight: 500;
  color: #fff;
  text-align: center;
}

@media (min-width: 48em) {
  .p-service__business--03 .p-service__title {
    margin-top: -6px;
    text-align: left;
  }
}

.p-service__title-ja,
.p-service__title-en {
  display: block;
}

.p-service__title-ja {
  font-size: 23px;
  letter-spacing: 0.2em;
}
@media (min-width: 48em) {
  .p-service__title-ja {
    font-size: 36px;
  }
}

.p-service__title-en {
  margin-top: 10px;
  font-family: var(--font-en);
  font-size: 11px;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .p-service__title-en {
    margin-top: 17px;
    font-size: 14px;
  }
}

.p-service__button {
  margin-top: 53px;
}
@media (min-width: 48em) {
  .p-service__button {
    text-align: center;
  }
}

@media (min-width: 48em) {
  .p-service__button:not(.p-service__button-new) .c-button {
    background-color: transparent;
    border-color: inherit;
  }
}

.p-service__business--03 .p-service__button {
  margin-top: 37px;
}
@media (min-width: 48em) {
  .p-service__business--03 .p-service__button {
    margin-top: 0;
  }
}

.p-service__obj01,
.p-service__obj02 {
  position: absolute;
  height: 0;
  background-color: var(--secondarycolor);
  transition: height cubic-bezier(0.23, 0.6, 0.43, 0.99) 0.8s;
  transform: rotate(45deg) skewY(-45deg);
  transform-origin: top right;
}
@media (min-width: 48em) {
  .p-service__obj01,
.p-service__obj02 {
    transition-duration: 1s;
  }
}

.p-service__obj01 {
  top: 0;
  right: -18px;
  width: 7px;
  opacity: 0.8;
}
@media (min-width: 48em) {
  .p-service__obj01 {
    top: -478px;
    right: -75px;
    width: 20px;
  }
}

.p-service.is-active .p-service__obj01 {
  height: 148px;
}
@media (min-width: 48em) {
  .p-service.is-active .p-service__obj01 {
    height: 800px;
  }
}

.p-service__obj02 {
  top: 25px;
  right: -87px;
  width: 56px;
  opacity: 0.3;
}
@media (min-width: 48em) {
  .p-service__obj02 {
    top: -100px;
    right: 113px;
    width: 105px;
    opacity: 0.1;
  }
}

.p-service.is-active .p-service__obj02 {
  height: 162px;
  transition-delay: 0.2s;
}
@media (min-width: 48em) {
  .p-service.is-active .p-service__obj02 {
    height: 522px;
    transition-delay: 0.3s;
  }
}

.p-works {
  margin-top: 50px;
}
@media (min-width: 48em) {
  .p-works {
    position: relative;
    margin-top: 172px;
  }
}

.p-works__content {
  padding: 0 25px;
  margin-top: 35px;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);
}
@media (min-width: 48em) {
  .p-works__content {
    padding: 0;
    margin-top: 60px;
  }
}
.p-works__content.is-active {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 48em) {
  .p-works__inner {
    display: flex;
    margin-left: -236px;
  }
}

@media (min-width: 48em) {
  .p-works__item {
    flex-shrink: 0;
  }
}
.p-works__item:nth-child(n+2) {
  margin-top: 37px;
}
@media (min-width: 48em) {
  .p-works__item:nth-child(n+2) {
    margin-top: 0;
  }
}

@media (min-width: 48em) {
  .p-works__img {
    overflow: hidden;
  }
}

.p-works__img img {
  width: 100%;
}
@media (min-width: 48em) {
  .p-works__img img {
    transition: transform 0.25s;
  }
}

@media (min-width: 48em) {
  .p-works__link:hover .p-works__img img {
    transform: scale(1.03);
  }
}

.p-works__block {
  margin-top: 18px;
}
@media (min-width: 48em) {
  .p-works__block {
    margin-top: 35px;
  }
}

.p-works__text {
  font-size: 12px;
  line-height: 1.9167;
  color: #999;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .p-works__text {
    font-size: 14px;
  }
}

.p-works__title {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .p-works__title {
    font-size: 24px;
    line-height: 1.9167;
  }
}

@media (min-width: 48em) {
  .p-works__link:hover .p-works__text,
.p-works__link:hover .p-works__title {
    text-decoration: underline;
  }
}

.p-works__tag {
  margin-top: 4px;
  margin-left: -5px;
}
@media (min-width: 48em) {
  .p-works__tag {
    margin-top: 8px;
  }
}

.p-works__tag span {
  display: inline-block;
  margin-top: 4px;
  margin-left: 5px;
  font-family: var(--font-en);
  font-size: 12px;
  color: var(--secondarycolor);
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .p-works__tag span {
    margin-top: 6px;
    font-size: 14px;
  }
}

.p-works__all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-top: 47px;
  font-family: var(--font-en);
  color: #fff;
  background-color: var(--basecolor);
}
@media (min-width: 48em) {
  .p-works__all {
    position: absolute;
    top: -180px;
    right: 50%;
    width: 390px;
    height: 80px;
    margin-top: 0;
    margin-right: -580px;
    font-size: 16px;
    transition: background-color 0.25s;
  }
}
@media (min-width: 48em) {
  .p-works__all:hover {
    background-color: var(--secondarycolor);
  }
}

.p-works__all-img {
  width: 10px;
  margin-right: 11px;
}
@media (min-width: 48em) {
  .p-works__all-img {
    margin-right: 16px;
  }
}

/*
.swiper-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @include mq(md) {
    margin-top: 50px;
  }
}

.swiper-pagination {
  margin: 0 28px;
  font-size: 15px;
  color: #001643;
  letter-spacing: -0.08em;

  @include mq(md) {
    margin: 0 37px;
    font-size: 16px;
  }
}
*/
.swiper-group {
  display: none;
}
@media (min-width: 48em) {
  .swiper-group {
    position: relative;
    top: -400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 0 45px;
    margin: 0 auto;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url("/assets/images/pages/common/result_slider_arrow.svg");
  background-size: contain;
}
@media (min-width: 48em) {
  .swiper-button-prev,
.swiper-button-next {
    width: 42px;
    height: 42px;
    cursor: pointer;
  }
  .swiper-button-prev:hover,
.swiper-button-next:hover {
    opacity: 0.7;
  }
}

.swiper-button-next {
  transform: scale(-1, 1);
}