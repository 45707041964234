.p-service {
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% - 42px);
    content: '';
    background-color: #f0f0f0;

    @include mq(md) {
      right: 0;
      width: 93.75%;
      height: calc(100% - 75px);
      margin: 0 auto;
    }
  }
}

.p-service__content {
  margin-top: 35px;

  @include mq(md) {
    margin-top: 67px;
  }
}

.p-service__inner {
  @include mq(md) {
    position: relative;
    display: flex;
    background-image: url('/assets/images/pages/index/service_img_md.jpg');
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
    transform: translateY(30px);
  }

  &.is-active {
    @include mq(md) {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.p-service__img {
  display: none;

  @include mq(md) {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 840px;
    background-position: center;
    background-size: cover;
    opacity: 0;
    object-fit: cover;
  }
}

.p-service__img--01 {
  @include mq(md) {
    background-image: url('/assets/images/pages/index/service_img01_hover.jpg');
  }
}

.p-service__img--02 {
  @include mq(md) {
    background-image: url('/assets/images/pages/index/service_img02_hover.jpg');
  }
}

.p-service__new {
  padding: 40px 25px 70px;

  @include mq(md) {
    max-width: 1340px;
    padding: 80px 90px 197px;
    margin: 0 auto;
  }
}

.p-service__business {
  background-position: 50%;
  background-size: cover;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);

  @include mq(md) {
    position: relative;
    z-index: 1;
    width: 50%;
    height: 840px;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-service__business--01 {
  background-image: url('/assets/images/pages/index/service_img01.jpg');

  @include mq(md) {
    background-image: none;
  }
}

.p-service__business--02 {
  background-image: url('/assets/images/pages/index/service_img02.jpg');

  @include mq(md) {
    background-image: none;
  }
}

.p-service__business--03 {
  padding: 43px 30px 52px;
  background-image: url('/assets/images/pages/index/service_img03.jpg');

  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 320px;
    padding: 0 50px 0 100px;
    background-image: url('/assets/images/pages/index/service_img03_md.jpg');
  }
}

.p-service__link {
  display: block;
  padding: 53px 25px 61px;

  @include mq(md) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 110px 0;
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  &.is-active {
    @include mq(md) {
      opacity: 1;
    }
  }
}

.p-service__title {
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.p-service__business--03 .p-service__title {
  @include mq(md) {
    margin-top: -6px;
    text-align: left;
  }
}

.p-service__title-ja,
.p-service__title-en {
  display: block;
}

.p-service__title-ja {
  font-size: 23px;
  letter-spacing: 0.2em;

  @include mq(md) {
    font-size: 36px;
  }
}

.p-service__title-en {
  margin-top: 10px;
  font-family: var(--font-en);
  font-size: 11px;
  letter-spacing: 0.1em;

  @include mq(md) {
    margin-top: 17px;
    font-size: 14px;
  }
}

.p-service__button {
  margin-top: 53px;

  @include mq(md) {
    text-align: center;
  }
}

.p-service__button:not(.p-service__button-new) .c-button {
  @include mq(md) {
    background-color: transparent;
    border-color: inherit;
  }
}

.p-service__business--03 .p-service__button {
  margin-top: 37px;

  @include mq(md) {
    margin-top: 0;
  }
}

.p-service__obj01,
.p-service__obj02 {
  position: absolute;
  height: 0;
  background-color: var(--secondarycolor);
  transition: height cubic-bezier(0.23, 0.6, 0.43, 0.99) 0.8s;
  transform: rotate(45deg) skewY(-45deg);
  transform-origin: top right;

  @include mq(md) {
    transition-duration: 1s;
  }
}

.p-service__obj01 {
  top: 0;
  right: -18px;
  width: 7px;
  opacity: 0.8;

  @include mq(md) {
    top: -478px;
    right: -75px;
    width: 20px;
  }
}

.p-service.is-active .p-service__obj01 {
  height: 148px;

  @include mq(md) {
    height: 800px;
  }
}

.p-service__obj02 {
  top: 25px;
  right: -87px;
  width: 56px;
  opacity: 0.3;

  @include mq(md) {
    top: -100px;
    right: 113px;
    width: 105px;
    opacity: 0.1;
  }
}

.p-service.is-active .p-service__obj02 {
  height: 162px;
  transition-delay: 0.2s;

  @include mq(md) {
    height: 522px;
    transition-delay: 0.3s;
  }
}
