.p-mv {
  position: relative;
  height: calc(100vh - 90px);
  // min-height: 100vh;
  // min-height: calc(var(--vh, 1vh) * 100);
  margin-top: 90px;
  border-top: 1px solid #b4b4b4;

  @include mq(md) {
    height: calc(100vh - 96px);
    margin-top: 96px;
  }
}

.p-mv__heading {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100%;
  padding: 0 0 0 30px;
  margin-top: -32px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.3846;
  color: #fff;
  letter-spacing: 0.2em;
  filter: blur(10px);
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transition-delay: 1s;
  transform: translate(-50%, -45%);

  @include mq(md) {
    top: 50%;
    max-width: 1200px;
    padding: 0 45px;
    margin-top: 0;
    font-size: 72px;
  }

  &.is-active {
    filter: blur(0);
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.p-mv__video {
  width: 100%;
  height: calc(100vh - 90px);
  object-fit: cover;

  @include mq(md) {
    height: calc(100vh - 96px);
  }
}

.p-mv__scroll {
  display: none;

  @include mq(md) {
    position: absolute;
    bottom: 0;
    left: 35px;
    display: inline-block;
    font-family: var(--font-en);
    font-size: 14px;
    color: #fff;
    writing-mode: vertical-rl;
  }

  &::before {
    @include mq(md) {
      position: absolute;
      bottom: 0;
      left: 8.5px;
      width: 1px;
      height: 100px;
      content: '';
      background-color: #f0f0f0;
      animation: scrolldown 1.8s infinite;
    }
  }

  &::after {
    @include mq(md) {
      display: inline-block;
      width: 1px;
      height: 100px;
      margin-top: 15px;
      content: '';
      background-color: rgba(240, 240, 240, 0.2);
    }
  }
}

@keyframes scrolldown {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }

  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }

  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }

  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
