.p-recruit {
  position: relative;
  padding: 90px 25px 70px;

  @include mq(md) {
    padding: 190px 3.3854166666666665% 240px;
  }
}

.p-recruit__container {
  padding: 45px 30px 50px;
  background-image: url('/assets/images/pages/index/recruit_img.jpg');
  background-position: 50% 0;
  background-size: cover;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);

  @include mq(md) {
    padding: 188px 0 158px;
    background-image: url('/assets/images/pages/index/recruit_img_md.jpg');
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-recruit__inner {
  @include mq(md) {
    position: relative;
    z-index: 1;
    max-width: 1480px;
    padding: 0 45px;
    margin: 0 auto;
  }
}

.p-recruit__heading {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #fff;
}

.p-recruit__heading-ja {
  margin-right: 10px;
  font-size: 16px;
  letter-spacing: 0.2em;

  @include mq(md) {
    margin-right: 19px;
    font-size: 36px;
  }
}

.p-recruit__heading-en {
  font-family: var(--font-en);
  font-size: 11px;

  @include mq(md) {
    margin-top: 5px;
    font-size: 14px;
  }
}

.p-recruit__lede {
  margin-top: 10px;
  font-size: 23px;
  font-weight: 500;
  line-height: 1.8696;
  color: #fff;
  letter-spacing: 0.2em;
  white-space: nowrap;
  font-feature-settings: initial;

  @include mq(md) {
    margin-top: 29px;
    margin-left: -5px;
    font-size: 72px;
    line-height: 1.5278;
    letter-spacing: 0.1em;
  }
}

.p-recruit__link {
  margin-top: 25px;

  @include mq(md) {
    margin-top: 52px;
  }
}

.p-recruit__obj01,
.p-recruit__obj02,
.p-recruit__obj03,
.p-recruit__obj04,
.p-recruit__obj05,
.p-recruit__obj06 {
  position: absolute;
  bottom: 100%;
  height: 0;
  background-color: var(--secondarycolor);
  transition: height cubic-bezier(0.23, 0.6, 0.43, 0.99) 0.8s;
  transform: rotate(45deg) skewY(-45deg);
  transform-origin: bottom left;

  @include mq(md) {
    transition-duration: 1s;
  }
}

.p-recruit__obj04,
.p-recruit__obj05,
.p-recruit__obj06 {
  z-index: -1;
  transform-origin: top right;
}

.p-recruit__obj01 {
  left: -35px;
  width: 5px;
  margin-bottom: 7px;
  opacity: 0.8;

  @include mq(md) {
    left: -99px;
    width: 20px;
    margin-bottom: -2px;
  }
}

.p-recruit.is-active .p-recruit__obj01 {
  height: 40px;

  @include mq(md) {
    height: 150px;
  }
}

.p-recruit__obj02 {
  left: -33px;
  width: 5px;
  margin-bottom: -9px;
  opacity: 0.3;

  @include mq(md) {
    left: -100px;
    width: 20px;
    margin-bottom: -84px;
  }
}

.p-recruit.is-active .p-recruit__obj02 {
  height: 80px;
  transition-delay: 0.2s;

  @include mq(md) {
    height: 350px;
    transition-delay: 0.3s;
  }
}

.p-recruit__obj03 {
  left: -107px;
  width: 53px;
  margin-bottom: -101px;
  opacity: 0.6;

  @include mq(md) {
    left: -362px;
    width: 210px;
    margin-bottom: -423px;
  }
}

.p-recruit.is-active .p-recruit__obj03 {
  height: 180px;
  transition-delay: 0.4s;

  @include mq(md) {
    height: 655px;
    transition-delay: 0.6s;
  }
}

.p-recruit__obj04 {
  top: 298px;
  right: -74px;
  width: 53px;
  opacity: 0.3;

  @include mq(md) {
    top: 442px;
    right: -297px;
    width: 210px;
  }
}

.p-recruit.is-active .p-recruit__obj04 {
  height: 232px;
  transition-delay: 0.8s;

  @include mq(md) {
    height: 910px;
  }
}

.p-recruit__obj05 {
  top: 420px;
  right: 30px;
  width: 5px;
  opacity: 0.8;

  @include mq(md) {
    top: 940px;
    right: 113px;
    width: 20px;
  }
}

.p-recruit.is-active .p-recruit__obj05 {
  height: 120px;
  transition-delay: 1.1s;

  @include mq(md) {
    height: 450px;
    transition-delay: 1.3s;
  }
}

.p-recruit__obj06 {
  top: 400px;
  right: -7px;
  width: 5px;
  opacity: 0.6;

  @include mq(md) {
    top: 838px;
    right: -70px;
    width: 20px;
  }
}

.p-recruit.is-active .p-recruit__obj06 {
  height: 102px;
  transition-delay: 1.4s;

  @include mq(md) {
    height: 350px;
    transition-delay: 1.6s;
  }
}
