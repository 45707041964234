.p-works {
  margin-top: 50px;

  @include mq(md) {
    position: relative;
    margin-top: 172px;
  }
}

.p-works__content {
  padding: 0 25px;
  margin-top: 35px;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);

  @include mq(md) {
    padding: 0;
    margin-top: 60px;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-works__inner {
  @include mq(md) {
    display: flex;
    margin-left: -236px;
  }
}

.p-works__item {
  @include mq(md) {
    flex-shrink: 0;
  }

  &:nth-child(n + 2) {
    margin-top: 37px;

    @include mq(md) {
      margin-top: 0;
    }
  }
}

.p-works__link {
  //
}

.p-works__img {
  @include mq(md) {
    overflow: hidden;
  }
}

.p-works__img img {
  width: 100%;

  @include mq(md) {
    transition: transform 0.25s;
  }
}

.p-works__link:hover .p-works__img img {
  @include mq(md) {
    transform: scale(1.03);
  }
}

.p-works__block {
  margin-top: 18px;

  @include mq(md) {
    margin-top: 35px;
  }
}

.p-works__text {
  font-size: 12px;
  line-height: 1.9167;
  color: #999;
  letter-spacing: 0.1em;

  @include mq(md) {
    font-size: 14px;
  }
}

.p-works__title {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.1em;

  @include mq(md) {
    font-size: 24px;
    line-height: 1.9167;
  }
}

.p-works__link:hover .p-works__text,
.p-works__link:hover .p-works__title {
  @include mq(md) {
    text-decoration: underline;
  }
}

.p-works__tag {
  margin-top: 4px;
  margin-left: -5px;

  @include mq(md) {
    margin-top: 8px;
  }
}

.p-works__tag span {
  display: inline-block;
  margin-top: 4px;
  margin-left: 5px;
  font-family: var(--font-en);
  font-size: 12px;
  color: var(--secondarycolor);
  letter-spacing: 0.1em;

  @include mq(md) {
    margin-top: 6px;
    font-size: 14px;
  }
}

.p-works__all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-top: 47px;
  font-family: var(--font-en);
  color: #fff;
  background-color: var(--basecolor);

  @include mq(md) {
    position: absolute;
    top: -180px;
    right: 50%;
    width: 390px;
    height: 80px;
    margin-top: 0;
    margin-right: -580px;
    font-size: 16px;
    transition: background-color 0.25s;
  }

  &:hover {
    @include mq(md) {
      background-color: var(--secondarycolor);
    }
  }
}

.p-works__all-img {
  width: 10px;
  margin-right: 11px;

  @include mq(md) {
    margin-right: 16px;
  }
}

/*
.swiper-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @include mq(md) {
    margin-top: 50px;
  }
}

.swiper-pagination {
  margin: 0 28px;
  font-size: 15px;
  color: #001643;
  letter-spacing: -0.08em;

  @include mq(md) {
    margin: 0 37px;
    font-size: 16px;
  }
}
*/

.swiper-group {
  display: none;

  @include mq(md) {
    position: relative;
    top: -400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 0 45px;
    margin: 0 auto;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url('/assets/images/pages/common/result_slider_arrow.svg');
  background-size: contain;

  @include mq(md) {
    width: 42px;
    height: 42px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.swiper-button-next {
  transform: scale(-1, 1);
}
